<template>
  <div :class="$style.container">
    <no-data-chart v-if="isNoData" />
    <div v-else :id="id" :class="$style.chartBox" :style="{ height }" />
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as echarts from 'echarts';
import resize from '@/utils/resize.js';
import { nowSize } from '@/utils/common.js';

const pieColors = [
  '#fbb440',
  '#01ce61',
  '#3d89f3',
  '#874dda',
  '#cd6fc5',
  '#ff9f7f',
];
const colorList = [
  '#1c9dff',
  '#47c267',
  '#41c6d9',
  '#58784c',
  '#e3a33c',
  '#874dda',
  '#cd6fc5',
  '#ff9f7f',
  '#ed8884',
  '#60cda0',
  '#006cff',
];
const roseOption = (data, options) => {
  const {
    cateKey = 'name',
    valueKey = 'value',
    title,
    roseType = 'radius',
  } = options;
  data = JSON.parse(JSON.stringify(data)).sort(
    (a, b) => b[valueKey] - a[valueKey],
  );
  let total = 0;
  title &&
    (total = data.reduce((p, c) => {
      return p + c[valueKey];
    }, 0));
  const legend = [];
  let length = data.length;
  if (length > 10) {
    data[9][cateKey] = '其他';
    for (let i = 10; i < length; i++) {
      data[9][valueKey] = +data[9][valueKey] + +data[i][valueKey];
    }
    data = data.slice(0, 10);
    length = 10;
  }
  if (length > 5) {
    for (let i = 0; i < 2; i++) {
      const half = Math.round(length / 2);
      const list = i ? data.slice(half) : data.slice(0, half);
      legend.push({
        right: i ? '20' : '80',
        top: 'center',
        orient: 'vertical',
        itemWidth: 10,
        itemHeight: 10,
        icon: 'rect',
        data: list.map(e => e[cateKey]),
        textStyle: {
          color: '#fff',
          fontSize: nowSize(10),
        },
        formatter(text) {
          return text.length > 3 ? text.substring(0, 3) + '…' : text;
        },
      });
    }
  } else {
    legend.push({
      right: '20',
      top: 'center',
      orient: 'vertical',
      itemWidth: nowSize(10),
      itemHeight: nowSize(10),
      icon: 'rect',
      data: data.map(e => e[cateKey]),
      textStyle: {
        color: '#fff',
        fontSize: nowSize(12),
      },
      formatter(text) {
        return text.length > 3 ? text.substring(0, 3) + '…' : text;
      },
    });
  }
  data = data.map(e => ({
    name: e[cateKey],
    value: e[valueKey],
  }));
  return {
    tooltip: {
      trigger: 'item',
      backgroundColor: 'rgba(3,29,63,0.6)',
      borderColor: '#0DC8FE',
      borderWidth: '1',
      padding: nowSize(10),
      textStyle: {
        color: '#fff',
        fontSize: nowSize(12),
      },
      formatter: params => {
        const {
          data: { name, value },
          percent,
        } = params;
        const txt = `${name}: ${value}, ${percent}%`;
        let str = '';
        const i = Math.floor(txt.length / 12);

        if (i > 1) {
          for (let j = 0; j < i; j++) {
            str += txt.substring(j * 12, j * 12 + 12) + '<br/>';
          }
        } else {
          str = txt;
        }
        return str;
      },
      position: function(pos, params, dom, rect, size) {
        // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
        var obj = { top: 60 };
        obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 4)]] = 5;
        return obj;
      },
      extraCssText: 'border-radius: 0;',
    },
    legend,
    color: colorList,
    series: [
      {
        type: 'pie',
        radius: [30, 50],
        center: ['35%', '55%'],
        tooltip: {
          show: false,
        },
        label: {
          show: true,
          position: 'center',
          color: '#fff',
          fontSize: nowSize(10),
          formatter: `{a|${title}}\n{b|${total}}`,
          rich: {
            a: {
              fontSize: nowSize(12),
              fontWeight: 'normal',
              color: '#fff',
              padding: 5,
            },
            b: {
              fontSize: nowSize(18),
              fontWeight: 'bold',
              color: '#fff',
            },
          },
        },
        labelLine: {
          normal: {
            show: false,
          },
        },
        data: [
          {
            value: 0,
            itemStyle: {
              normal: {
                color: 'rgba(50,197,233,0)',
              },
            },
          },
        ],
      },
      {
        type: 'pie',
        radius: roseType ? [45, 85] : [45, 75],
        center: ['35%', '55%'],
        roseType,
        label: {
          color: '#fff',
          formatter({ percent }) {
            return `${percent}%`;
          },
          position: 'outer',
          alignTo: 'labelLine',
          fontSize: nowSize(10),
        },
        labelLine: {
          length: 0,
          length2: 5,
        },
        data,
      },
    ],
  };
};
const pieOption = (data, options) => {
  const { cateKey = 'name', valueKey = 'value' } = options;
  data = data.map(e => ({
    name: e[cateKey],
    value: e[valueKey],
  }));
  return {
    tooltip: {
      trigger: 'item',
      backgroundColor: 'rgba(3,29,63,0.6)',
      borderColor: '#0DC8FE',
      borderWidth: '1',
      padding: nowSize(10),
      textStyle: {
        color: '#fff',
        fontSize: '14px',
      },
      formatter: params => {
        const {
          data: { name, value },
          percent,
        } = params;
        return `${name}: ${value}, ${percent}%`;
      },
      extraCssText: 'border-radius: 0;',
    },
    legend: {
      left: 'center',
      bottom: 10,
      itemWidth: nowSize(12),
      itemHeight: nowSize(8),
      icon: 'rect',
      data: data.map(e => e.name),
      textStyle: {
        color: '#fff',
        fontSize: nowSize(12),
      },
    },
    color: pieColors,
    series: [
      {
        type: 'pie',
        radius: 70,
        center: ['50%', '45%'],
        label: {
          fontSize: nowSize(12),
          formatter({ percent }) {
            return `${percent}%`;
          },
          color: '#fff',
          position: 'outer',
          alignTo: 'labelLine',
        },
        labelLine: {
          length: 0,
          length2: 5,
        },
        data,
      },
    ],
  };
};
/**
 * @class pie 系列可设为环形图或玫瑰图
 */
@Component({
  mixins: [resize],
  mounted() {
    if (this.data && this.data.length) this.initChart();
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
})
export default class AreaRoseChart extends Vue {
  @Prop({ type: String, default: 'roseChart' }) id;
  @Prop({ type: String, default: '100%' }) height;
  @Prop({ type: Array, default: null }) data;
  @Prop({ type: Object, default: () => ({}) }) options;
  @Prop({ type: String, default: 'rose' }) chartType;
  get isNoData() {
    if (!this.data || !this.data.length) {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
      return true;
    } else {
      this.$nextTick(this.initChart);
      return false;
    }
  }
  getOptions(...params) {
    return this.chartType === 'rose'
      ? roseOption(...params)
      : pieOption(...params);
  }
  initChart() {
    if (!this.roseChart)
      this.chart = echarts.init(document.getElementById(this.id));
    const options = this.getOptions(this.data, this.options);
    this.chart.setOption(options);
  }
}
</script>

<style lang="less" module>
.container {
  height: 100%;
  position: relative;
}
.chartBox {
  width: 100%;
}
</style>
