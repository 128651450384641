<template>
  <div :class="$style.container">
    <no-data-chart v-if="isNoData" />
    <div v-else :id="id" :class="$style.chartBox" :style="{ height }" />
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as echarts from 'echarts';
import resize from '@/utils/resize.js';
import { nowSize } from '@/utils/common.js';

const barOption = (data, that) => {
  const category = data.map(e => e.key);
  const values = data.map(e => e.num);
  let max = Math.max(...values, 0);
  max += 4 - (max % 4);
  const grid = {
    x: 55,
    y: 30,
    x2: 30,
    y2: 30,
  };
  const series = [
    {
      type: 'bar',
      hoverAnimation: false,
      data: values,
      barWidth: '20px',
      itemStyle: {
        normal: {
          // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
          color: new echarts.graphic.LinearGradient(
            0,
            1,
            0,
            0,
            [
              {
                offset: 0,
                color: '#2193fe', // 0% 处的颜色
              },
              {
                offset: 1,
                color: '#0ac8ff', // 100% 处的颜色
              },
            ],
            false,
          ),
        },
      },
    },
  ];
  const tooltip = {
    trigger: 'item',
    axisPointer: { type: 'none' },
    backgroundColor: 'rgba(3,29,63,0.6)',
    borderColor: '#0DC8FE',
    borderWidth: '1',
    padding: 10,
    textStyle: {
      color: '#fff',
      fontSize: '14px',
    },
    formatter(params) {
      try {
        const { name, value } = params;
        const [month, day] = name.split('-');
        return `<span>${month}-${day}： ${value}${that.$t(
          'screen.times',
        )}</span>`;
      } catch (error) {
        return '';
      }
    },
    extraCssText: 'border-radius: 0;',
  };
  return {
    tooltip,
    grid,
    xAxis: {
      type: 'category',
      axisTick: {
        show: false,
        alignWithLabel: true,
      },
      data: category,
      axisLine: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        color: '#a9adc0',
        fontSize: nowSize(12),
      },
    },
    yAxis: {
      type: 'value',
      min: 0,
      max,
      interval: max / 4, // 间隔
      splitLine: {
        show: true,
        lineStyle: {
          color: '#121329',
        },
      },
      axisLabel: {
        textStyle: {
          color: '#9ca0b0',
        },
        formatter(val) {
          return val + that.$t('screen.times');
        },
        fontSize: nowSize(12),
      },
      // 隐藏刻度线
      axisTick: { show: false },
      axisLine: {
        show: false,
      },
    },

    series,
  };
};
/**
 * @class bar 图系 单bar 或 叠bar
 */
@Component({
  mixins: [resize],
  mounted() {
    if (this.data && this.data.length) this.initChart();
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
})
export default class WarnTrend extends Vue {
  @Prop({ type: String, default: 'barChart' }) id;
  @Prop({ type: String, default: '100%' }) height;
  @Prop({ type: Array, default: null }) data;
  get isNoData() {
    if (!this.data || !this.data.length) {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
      return true;
    } else {
      this.$nextTick(this.initChart);
      return false;
    }
  }
  initChart() {
    if (!this.roseChart)
      this.chart = echarts.init(document.getElementById(this.id));
    const options = barOption(this.data, this);
    this.chart.setOption(options);
  }
}
</script>

<style lang="less" module>
.container {
  height: 100%;
  position: relative;
}
.chartBox {
  width: 100%;
}
</style>
