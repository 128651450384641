<template>
  <div :class="$style.container">
    <no-data-chart v-if="isNoData" />
    <div v-else :id="id" :class="$style.chartBox" :style="{ height }" />
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as echarts from 'echarts';
import resize from '@/utils/resize.js';
import { nowSize } from '@/utils/common.js';

const lineOption = (data, options) => {
  const { cateKey = 'name', valueKey = 'value' } = options;
  return {
    tooltip: {
      trigger: 'item',
      backgroundColor: 'rgba(3,29,63,0.6)',
      borderColor: '#0DC8FE',
      borderWidth: '1',
      padding: 10,
      textStyle: {
        color: '#fff',
        fontSize: '14px',
      },
      formatter({ value, name }) {
        return `<span>${name}： ${value}%</span>`;
      },
      extraCssText: 'border-radius: 0;',
      axisPointer: {
        type: 'line',
        z: 1,
        lineStyle: {
          color: '#fff',
          opacity: 0.2,
        },
      },
    },
    grid: {
      x: 50,
      y: 30,
      x2: 30,
      y2: 30,
    },

    xAxis: {
      type: 'category',
      boundaryGap: true,
      axisTick: {
        show: false,
      },
      data: data.map(e => e[cateKey]),
      axisLine: {
        show: false,
        lineStyle: {
          show: false,
          color: '#b8b8bb',
          fontSize: 10,
        },
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        color: '#a9adc0',
        fontSize: nowSize(12),
      },
    },
    yAxis: {
      type: 'value',
      min: 0,
      max: 100,
      interval: 25, // 间隔
      splitLine: {
        show: true,
        lineStyle: {
          color: '#121329',
        },
      },
      axisLabel: {
        textStyle: {
          color: '#9ca0b0',
        },
        fontSize: nowSize(12),
        formatter(val) {
          return val + '%';
        },
      },
      // 隐藏刻度线
      axisTick: { show: false },
      axisLine: {
        show: false,
      },
    },

    series: [
      {
        type: 'line',
        stack: '总量',
        smooth: true,
        symbol: 'circle', //设定为实心点
        symbolSize: 10,
        hoverAnimation: false,
        data: data.map(e => e[valueKey]),
        itemStyle: {
          normal: {
            // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
            color: '#fe8b36', // 背景渐变色
            lineStyle: {
              // 系列级个性化折线样式
              width: 3,
              type: 'solid',
              color: '#fe8b36',
            },
          },
          emphasis: {
            color: '#fe8b36',
            borderWidth: 10,
            lineStyle: {
              // 系列级个性化折线样式
              width: 2,
              type: 'dotted',
              color: '#fe8b36', // 折线的颜色
            },
          },
        }, // 线条样式
        areaStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgba(254, 139, 54,0.39)',
              },
              {
                offset: 0.34,
                color: 'rgba(254, 139, 54,0.25)',
              },
              {
                offset: 1,
                color: 'rgba(254, 139, 54,0.00)',
              },
            ]),
          },
        },
      },
    ],
  };
};
/**
 * @class line 图
 */
@Component({
  mixins: [resize],
  mounted() {
    if (this.data && this.data.length) this.initChart();
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
})
export default class lineChart extends Vue {
  @Prop({ type: String, default: 'lineChart' }) id;
  @Prop({ type: String, default: '100%' }) height;
  @Prop({ type: Array, default: null }) data;
  @Prop({ type: Object, default: () => ({}) }) options;
  get isNoData() {
    if (!this.data || !this.data.length) {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
      return true;
    } else {
      this.$nextTick(this.initChart);
      return false;
    }
  }
  initChart() {
    if (!this.chart) {
      this.chart = echarts.init(document.getElementById(this.id));
      this.chart.on('click', e => {
        const index =
          e.componentType === 'series'
            ? e.dataIndex
            : this.chart.getOption().xAxis[0].data.indexOf(e.value);
        this.$emit('showSafeModal', index);
      });
    }
    const options = lineOption(this.data, this.options);
    this.chart.setOption(options);
  }
}
</script>

<style lang="less" module>
.container {
  height: 100%;
  position: relative;
}

.chartBox {
  width: 100%;
}
</style>
