import Vue from 'vue';
import VueAMap from 'vue-amap';

const weatherList = [
  { title: '晴', icon: 'icontianqing' },
  { title: '多云', icon: 'iconduoyun' },
  { title: '阴转多云', icon: 'iconduoyun' },
  { title: '阴', icon: 'iconyintian' },
  { title: '阵雨', icon: 'icondongyu' },
  { title: '小雨', icon: 'icondongyu' },
  { title: '中雨', icon: 'icondongyu' },
  { title: '大雨', icon: 'icondayu' },
  { title: '暴雨', icon: 'icondayu' },
  { title: '冻雨', icon: 'icondongyu1' },
  { title: '大暴雨', icon: 'icondayu' },
  { title: '特大暴雨', icon: 'icondayu' },
  { title: '雷阵雨', icon: 'iconleizhenyu' },
  { title: '雷阵雨夹冰雹', icon: 'iconyujiaxue' },
  { title: '阵雪', icon: 'iconxiaxue' },
  { title: '小雪', icon: 'iconxiaxue' },
  { title: '中雪', icon: 'iconzhongxue' },
  { title: '大雪', icon: 'icondaxue1' },
  { title: '暴雪', icon: 'iconbaoxue' },
  { title: '雾', icon: 'icondawu' },
  { title: '沙尘暴', icon: 'iconshachenbao' },
  { title: '浮尘', icon: 'iiconianqizitiku46' },
  { title: '扬沙', icon: 'iconyangsha' },
  { title: '强沙尘暴', icon: 'iconshachenbao' },
  { title: '霾', icon: 'icondawu' },
];
export default class Weatcher {
  constructor() {
    this.list = weatherList;
  }

  static initVueAmapOptions() {
    Vue.use(VueAMap);
    VueAMap.initAMapApiLoader({
      key: process.env.VUE_APP_AMAP_WEB_API_KEY,
      plugin: ['AMap.Geocoder'],
      v: '1.4.15',
    });
  }

  /**
   * @name 获取天气
   * @param { String } weather 天气情况
   */
  getWeatch(weather) {
    const item = this.list.find(el => {
      return el.title === weather;
    });
    return item ? item.icon : this.list[0].icon;
  }
}

export const weatchObj = new Weatcher();
