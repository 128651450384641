//-------- 总包和安监局（区域） ------------
/**@name 安全指数趋势 */
export const APP_ID_DAY_RANGE_SAFETY_RATE_COUNT =
  'APP_ID_DAY_RANGE_SAFETY_RATE_COUNT';
/**@name 预警统计、预警趋势 */
export const APP_ID_DAY_RANGE_ALARM_MESSAGE_ADDRESS_LIST =
  'APP_ID_DAY_RANGE_ALARM_MESSAGE_ADDRESS_LIST';
/**@name 项目平均预警数排行 */
export const APP_ID_PROJECT_AVERAGE_RANK_ALARM =
  'APP_ID_PROJECT_AVERAGE_RANK_ALARM';
/**@name 楼栋平均提升排行 */
export const APP_ID_BUILDING_AVERAGE_RISE_COUNT =
  'APP_ID_BUILDING_AVERAGE_RISE_COUNT';
/**@name 项目安全指数排行 */
export const APP_ID_PROJECT_SAFETY_RATE_COUNT =
  'APP_ID_PROJECT_SAFETY_RATE_COUNT';

//-------- 租赁商 ------------
/** @name 承包商大屏安全指数趋势 */
export const APP_ID_CONTRACTOR_DAY_RANGE_SAFETY_RATE_COUNT =
  'APP_ID_CONTRACTOR_DAY_RANGE_SAFETY_RATE_COUNT';
/** @name 承包商大屏预警趋势、预警统计 */
export const APP_ID_DAY_RANGE_ALARM_MESSAGE_CONTRACTORLIST =
  'APP_ID_DAY_RANGE_ALARM_MESSAGE_CONTRACTORLIST';
/** @name 承包商大屏平均预警数排行  */
export const APP_ID_CONTRACTOR_AVERAGE_RANK_BY_ALARM =
  'APP_ID_CONTRACTOR_AVERAGE_RANK_BY_ALARM';
/** @name 承包商大屏楼栋平均提升天数排行  */
export const APP_ID_CONTRACTOR_BUILDING_AVERAGE_RISE_COUNT =
  'APP_ID_CONTRACTOR_BUILDING_AVERAGE_RISE_COUNT';
/** @name 承包商大屏项目安全指数排行 */
export const APP_ID_CONTRACTOR_SAFETY_RATE_COUNT =
  'APP_ID_CONTRACTOR_SAFETY_RATE_COUNT';
