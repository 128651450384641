<template>
  <div class="chartMap">
    <div
      class="echarts chartMapBox"
      :style="{ height: height, width: width }"
      v-loading="loading"
    >
      <div :id="id" :style="{ height: height, width: width }"></div>
    </div>
    <div class="backMapWrap" v-if="mapUrlQueueSize !== 1">
      <span class="backMapLabel" @click="backTopMapArea">{{
        $t('screen.btn.back')
      }}</span>
    </div>
    <ul class="tipBoxWrap">
      <li
        class="tipItem"
        v-for="(item, index) in buttonGroups"
        :key="item.key"
        @click="handleSelectButton(index, item)"
      >
        <span
          class="iconBox"
          :style="[
            item.selected
              ? { backgroundColor: item.color }
              : { backgroundColor: '#929292' },
          ]"
        ></span>
        <span class="labelBox">{{ $t(item.label) }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import Weatcher, { weatchObj } from '@/assets/js/weather.js';
import { getAddressInfoByAmap } from '@/services/iot-electric-box/map.js';
import { getGeoData, getChinaData } from '@/services/oss/oss.js';
import { gcj02tobd09 } from '@/utils';
import { generalScreenModule } from '@/enum/store.js';
import { debounce } from 'lodash-decorators';
import {
  Color_Rising,
  Color_Waiting,
  Color_Building,
  Color_Outing,
  Color_Electricbox,
  LEVEL_TYPE_COUNTRY,
  LEVEL_TYPE_PROVINCE,
  LEVEL_TYPE_CITY,
  LEVEL_TYPE_DISTRICT,
  LEVEL_WAITING,
  LEVEL_BUILDING,
  LEVEL_RISING,
  LEVEL_OUTING,
  LEVEL_ELECTRIC_BOX,
} from '@/components/main-screen/final.js';

Weatcher.initVueAmapOptions();

/** @name 缓存区域和项目的tooltip */
const areaCountMap = new Map();
const scatterMap = new Map();

/** @name 缓存websocket传入的项目 */
const projectListMap = new Map();

function noop() {}

/** @name 项目数据加载完成事件 */
const LOADED_COMPLETE_PROJECT_LIST = 'project-list-register';
/** @name 地图加载完成事件 */
const LOADED_COMPLETE_MAP = 'map-register';

/**
 * @typedef Address 地图的地址（省市区）
 * @property {string} country 全国 or 国家
 * @property {string} province 省
 * @property {string} ctiy 市
 * @property {string} area 县区
 */
/**
 * @typedef QueueMap 地图层级Map
 * @property {string} level 地图的层级
 * @property {string} adcode 地图的地理编码
 * @property {string} value 地图的名称
 * @property {Address} address 地图的省市区
 */
@Component()
export default class GeneralMap extends Vue {
  /** @type QueueMap */
  @generalScreenModule.Getter lastMapUrlQueueItem;
  @generalScreenModule.Getter waitingButton;
  @generalScreenModule.Getter buildingButton;
  @generalScreenModule.Getter electricBoxButton;
  @generalScreenModule.Getter risingButton;
  @generalScreenModule.Getter outtingButton;
  @generalScreenModule.State mapUrlQueue; // 跳转地址队列
  @generalScreenModule.State buttonGroups;
  @generalScreenModule.Action addMapUrlQueue;
  @generalScreenModule.Action deleteMapUrlQueueOfPop;
  @generalScreenModule.Action updateButtonGroups;
  @generalScreenModule.Mutation SET_MAP_URL_QUEUE;
  /** @name 访客信息 */
  @generalScreenModule.State visitorInfo;
  @Watch('visitorInfo', { deep: true, immediate: true })
  async visitorInfoChange() {
    await this.inintMapUrlQueue({
      value: this.defaultSiteValue,
      level: this.areaLevel,
    });
    await this.initChart();
  }
  get mapUrlQueueSize() {
    return this.mapUrlQueue.length;
  }

  @Prop({ type: String, default: 'GeneralMap' }) id;
  @Prop({ type: String, default: '100%' }) width;
  @Prop({ type: String, default: '100%' }) height;
  @Prop({ type: Function, default: noop }) handleMoveMapProjectForData;
  @Prop({ type: Function, default: noop }) handleMoveMapForArea;

  hasRegisterData = true; // 默认注册
  async mounted() {
    this.$on(LOADED_COMPLETE_MAP, result => {
      const data = projectListMap.get(result);
      if (data) {
        setTimeout(() => {
          this.triggerRegisterEvent(data);
        }, 0);
      } else {
        this.hasRegisterData = false;
      }
    });
    this.$on(LOADED_COMPLETE_PROJECT_LIST, data => {
      if (!this.hasRegisterData) {
        this.hasRegisterData = true;
        this.triggerRegisterEvent(data);
      }
    });
  }
  beforeDestroy() {
    this.SET_MAP_URL_QUEUE([]);
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }

  async handleSelectButton(index, item) {
    this.updateButtonGroups({
      index,
      selected: !item.selected,
    });
    await this.initLoadMap();
    this.triggerRegisterEvent(this.projectObject);
  }

  /** @description country province city district  */
  type = LEVEL_TYPE_COUNTRY;
  countryMapName = 'china';
  provineMapName = '';
  cityMapName = '';
  districtMapName = '';
  selected = 'china';

  /**
   * @name 直接进入页面的数据
   * @description 是否有区域编号 adcode
   */
  async inintMapUrlQueue(data) {
    try {
      const { address } = this.visitorInfo;
      let adcode = '';
      this.type = this.areaLevel;
      switch (this.areaLevel) {
        case LEVEL_TYPE_COUNTRY:
          this.countryMapName = 'china';
          break;
        case LEVEL_TYPE_PROVINCE:
          this.provineMapName = data.value;
          break;
        case LEVEL_TYPE_CITY:
          this.provineMapName = address.province;
          this.cityMapName = data.value;
          break;
        case LEVEL_TYPE_DISTRICT:
          this.districtMapName = address.area;
          this.provineMapName = address.province;
          this.cityMapName = address.city;
          break;
      }
      if (data.adcode) {
        adcode = data.adcode;
      } else {
        adcode = (await this.getAdcodeByArea(data.value)).adcode;
      }
      this.addMapUrlQueue({
        ...data,
        adcode,
      });

      // eslint-disable-next-line no-empty
    } catch (error) {}
  }
  /** @name 获取区域编码或者区域名称 */
  getAdcodeByArea(address) {
    return new Promise((resolve, reject) => {
      getAddressInfoByAmap({
        keywords: address,
      }).then(result => {
        if (!result) reject(new Error('no data'));
        let message = '';
        try {
          const { districts } = JSON.parse(result);
          if (districts[0] && districts[0].adcode) {
            message = districts[0];
          } else {
            message = {
              adcode: '100000',
              name: '中国',
            };
          }
        } catch (error) {
          message = {
            adcode: '100000',
            name: '中国',
          };
        }
        resolve(message);
      });
    });
  }

  /**
   * @name 后台设置分享地图的层级
   * @description  country province city district
   */
  get areaLevel() {
    try {
      const {
        address: { area, city, province },
      } = this.visitorInfo;
      if (!area && !city && !province) {
        return LEVEL_TYPE_COUNTRY;
      }
      if (!area && !city && province) {
        return LEVEL_TYPE_PROVINCE;
      }
      if (!area && city && province) {
        return LEVEL_TYPE_CITY;
      }
      if (area && city && province) {
        return LEVEL_TYPE_DISTRICT;
      }
    } catch (error) {
      return LEVEL_TYPE_COUNTRY;
    }
    return LEVEL_TYPE_COUNTRY;
  }
  get defaultSiteValue() {
    const { area, city, province } = this.visitorInfo.address ?? {};
    if (!area && !city && !province) {
      return this.countryMapName;
    }
    if (!area && !city && province) {
      return province;
    }
    if (!area && city && province) {
      return city;
    }
    if (area && city && province) {
      return area;
    }
    return this.countryMapName;
  }

  /** @name webscoket传递数据到区域 */
  handleMoveDataByArea(data) {
    const key = `${this.currentArea.name}`;
    const fIndex = this.areaCacheQueue.findIndex(v => v === key);
    this.areaCacheQueue.splice(fIndex, 1);
    areaCountMap.set(key, data);
    this.areaCallback(
      this.areaTicket,
      this.areaDialogHtml(this.currentArea, data),
    );
  }
  areaTicket = undefined;
  areaCallback = noop;
  currentArea = {};
  areaCacheQueue = []; // tooltip 数据

  getFormatter(params, ticket, callback) {
    this.areaTicket = ticket;
    this.areaCallback = callback;
    this.currentArea = params;
    const key = `${params.name}`;
    const areaCacheOne = this.areaCacheQueue.find(v => v === key);
    if (areaCacheOne) {
      return 'Loading';
    }
    if (areaCountMap.has(key)) {
      return this.areaDialogHtml(params, areaCountMap.get(key));
    } else {
      this.reqMapApi(params, key);
      return 'Loading';
    }
  }
  @debounce(1000)
  reqMapProjectApi(key, id) {
    this.projectCacheQueue.push(key);
    this.handleMoveMapProjectForData(id);
  }
  @debounce(1000)
  reqMapApi(params, key) {
    this.areaCacheQueue.push(key);
    const { id: screenManageId, password } = this.visitorInfo;
    const object = {
      screenManageId: screenManageId, // 管理大屏id,
      password: password, // 密码
      address: {
        province: '',
        city: '',
        area: '',
      },
    };
    if (this.type === LEVEL_TYPE_COUNTRY) {
      object.address.province = this.formatProvinceName(params.name);
    }
    if (this.type === LEVEL_TYPE_PROVINCE) {
      object.address.province = this.formatProvinceName(this.provineMapName);
      object.address.city = params.name;
    }
    if (this.type === LEVEL_TYPE_CITY) {
      object.address.province = this.formatProvinceName(this.provineMapName);
      object.address.city = this.cityMapName;
      object.address.area = params.name;
    }
    this.handleMoveMapForArea(object);
  }
  /** @name echarts配置 */
  defaultOptions(geo) {
    return new Promise(resolve => {
      const options = {
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgba(3,29,63,0.6)', // 通过设置rgba调节背景颜色与透明度
          color: '#fff',
          borderWidth: '1',
          borderColor: '#0DC8FE',
          textStyle: {
            color: '#fff',
            fontSize: this.$size(14),
          },
          extraCssText: 'border-radius: 0; ',
          formatter: this.getFormatter,
        },

        geo: {
          map: geo,
          roam: true,
          scaleLimit: {
            min: 0.4,
            max: 38,
          },
          itemStyle: {
            areaColor: 'rgba(3, 38, 63, 0.3)',
            borderColor: '#05D3FF',
          },
          emphasis: {
            itemStyle: {
              areaColor: 'rgba(5, 193, 236, 0.4)',
            },
            label: {
              show: false,
            },
          },
          select: {
            itemStyle: {
              areaColor: 'rgba(5, 193, 236, 0.4)',
            },
            label: {
              show: false,
            },
          },
          label: {
            normal: {
              show: false,
              color: '#888',
            },
          },
        },
        series: [],
      };

      /** @name 待进场项目 */
      const waitingSeries = Object.assign({}, this.formatSeriesObject(), {
        symbolSize: this.$size(8),
        data: [],
        itemStyle: {
          color: Color_Waiting,
        },
        z: 11,
      });
      /** @name 已退场项目 */
      const outingSeries = Object.assign({}, this.formatSeriesObject(), {
        symbolSize: this.$size(8),
        data: [],
        itemStyle: {
          color: Color_Outing,
        },
        z: 10,
      });
      /** @name 已进场项目 */
      const buildingSeries = Object.assign({}, this.formatSeriesObject(), {
        symbolSize: this.$size(10),
        data: [],
        itemStyle: {
          color: Color_Building,
        },
        z: 14,
      });
      /** @name 提升中项目 */
      const risingSeries = Object.assign({}, this.formatSeriesObject(), {
        type: 'effectScatter',
        symbolSize: this.$size(10),
        data: [],
        itemStyle: {
          color: Color_Rising,
        },
        rippleEffect: {
          scale: 2.8,
        },
        z: 16,
      });
      /** @name 已进场有安监终端项目 */
      const electricBoxSeries = Object.assign({}, this.formatSeriesObject(), {
        // type: 'effectScatter',
        symbolSize: this.$size(10),
        data: [],
        itemStyle: {
          color: Color_Electricbox,
        },
        // rippleEffect: {
        //   scale: 2.8,
        // },
        z: 15,
      });
      const series = [
        waitingSeries,
        outingSeries,
        buildingSeries,
        electricBoxSeries,
        risingSeries,
      ];
      series.push({
        name: this.$t('matterwebset.chart.chart1'),
        type: 'map',
        geoIndex: 0,
        data: [],
        dataRange: {
          show: false,
        },
      });
      options.series = series;
      resolve(options);
    });
  }
  formatProvinceName(address) {
    if (`${address}`.includes('省')) {
      return address;
    } else {
      if (address === '北京') {
        return `${address}`;
      } else if (
        address === '天津' ||
        address === '重庆' ||
        address === '上海'
      ) {
        return `${address}市`;
      } else if (address === '广西') return '广西壮族自治区';
      else if (address === '西藏') return '西藏自治区';
      else if (address === '宁夏') return '宁夏回族自治区';
      else if (address === '新疆') return '新疆维吾尔自治区';
      else if (address === '内蒙古') return '内蒙古自治区';
      else return `${address}省`;
    }
  }
  /** @name 区域弹窗 */
  areaDialogHtml(params, value) {
    const obj = value;
    let str = `<div class="toolTipTitle" >${params.name}</div>`;
    if (value.projectQuantity >= 0) {
      str += `
        <div class="toolTipBuild">
          <span class="labelTip">${this.$t('screen.count.projects')}：</span>
          <span class="valueTip">${obj.projectQuantity}</span>
        </div>
        <div class="toolTipBuild">
          <span class="labelTip">${this.$t('screen.warningTotalcount')}：</span>
          <span class="valueTip">${obj.alarmQuantity}</span>
        </div>
        <div class="toolTipBuild">
          <span class="labelTip">${this.$t(
            'screen.count.startedProjects',
          )}：</span>
          <span class="valueTip">${obj.underProjectQuantity}</span>
        </div>
        <div class="toolTipBuild">
          <span class="labelTip">${this.$t(
            'screen.count.startedBuilding',
          )}：</span>
          <span class="valueTip">${obj.underBuildQuantity}</span>
        </div>
        ${
          obj.thisMonthSafeIndex
            ? `
                <div class="toolTipBuild">
                  <span class="labelTip">${this.$t(
                    'screen.count.monthlySafeRate',
                  )}：</span>
                  <span class="valueTip">${obj.thisMonthSafeIndex}%</span>
                </div>
              `
            : ''
        } <div class="toolTipBuild">
        <div class="toolTipBuild">
          <span class="labelTip">${this.$t(
            'matterwebset.generalscreen.warningnum7',
          )}：</span>
          <span class="valueTip">${obj.beforeSevenDayAlarmQuantity}</span>
        </div>
      `;
    } else {
      str += this.$t('common.nodata');
    }

    const resultHtml = `
      <div class="screenAreaBox">
        ${str}
      </div>
    `;
    return resultHtml;
  }

  /** @name webscoket传递数据到项目 */
  handleMoveDataByProject(data) {
    let value = {
      icon: '',
      name: '',
      low: '',
    };
    try {
      const {
        weatherInfoResponse: {
          lives: [weatherInfo],
        },
      } = data;
      value = {
        icon: weatchObj.getWeatch(weatherInfo.weather),
        name: weatherInfo.weather,
        low: weatherInfo.temperature,
      };
    } catch (error) {
      value = {
        icon: 'iconduoyun',
        name: '多云',
        low: '22',
      };
    }
    const key = `${this.currentProject.lng}_${this.currentProject.lat}`;
    const fIndex = this.projectCacheQueue.findIndex(v => v === key);
    this.projectCacheQueue.splice(fIndex, 1);
    scatterMap.set(key, { value, object: data });
    this.pointCallback(
      this.pointTicket,
      this.scatterTooltipForHtml(data, value),
    );
  }
  pointTicket = undefined;
  pointCallback = noop;
  currentProject = {}; // 当前项目
  projectCacheQueue = [];
  /** @name 项目series配置 */
  formatSeriesObject() {
    return {
      symbol: 'circle',
      symbolSize: 24,
      type: 'scatter', // series图表类型 effectScatter动画
      coordinateSystem: 'geo',
      data: [],
      tooltip: {
        backgroundColor: 'rgba(3,29,63,0.6)',
        color: '#fff',
        borderWidth: '1',
        borderColor: '#0DC8FE',
        textStyle: {
          color: '#fff',
          fontSize: this.$size(14),
        },
        extraCssText: 'border-radius: 0; ',
        trigger: 'item',
        formatter: (params, ticket, callback) => {
          this.pointTicket = ticket;
          this.pointCallback = callback;
          const obj = params.data && params.data.lng && params.data;
          this.currentProject = obj;
          const key = `${obj.lng}_${obj.lat}`;
          const projectCacheOne = this.projectCacheQueue.find(v => v === key);
          if (projectCacheOne) {
            return 'Loading';
          }
          if (scatterMap.has(key)) {
            const { value, object } = scatterMap.get(key);
            return this.scatterTooltipForHtml(object, value);
          } else {
            this.reqMapProjectApi(key, params.data.id);
            return 'Loading';
          }
        },
      },
    };
  }
  /** @name 散点标记的图例 */
  scatterTooltipForHtml(obj, val) {
    return `
      <div class="projectBuildTool">
        <p class="titleTool">${obj.name}</p>
        <div class="toolTipBuild">
          <span class="labelTip">${this.$t('screen.count.buildings')}：</span>
          <span class="valueTip">${obj.buildQuantity}</span>
        </div>
        <div class="toolTipBuild">
          <span class="labelTip">${this.$t('screen.warningTotalcount')}：</span>
          <span class="valueTip">${obj.alarmQuantity}</span>
        </div>
        ${
          obj.thisMonthSafeIndex
            ? `
              <div class="toolTipBuild">
                <span class="labelTip">${this.$t(
                  'screen.count.monthlySafeRate',
                )}：</span>
              <span class="valueTip">${obj.thisMonthSafeIndex}</span>
              </div>
            `
            : ''
        }
        <div class="toolTipBuild">
          <span class="labelTip">${this.$t(
            'electricBox.mg.projectLeader',
          )}：</span>
          <span class="valueTip">${
            obj.principal ? obj.principal : this.$t('screen.noDataYet')
          }</span>
        </div>
        <div class="toolTipBuild">
          <span class="labelTip">${this.$t('screen.phoneNumber')}：</span>
          <span class="valueTip">${
            obj.phone ? obj.phone : this.$t('screen.noDataYet')
          }</span>
        </div>
        <div class="toolTipBuild">
          <i class="TC tc-icon-dizhi3"></i>
          ${
            obj.projectLocation
              ? obj.projectLocation
              : this.$t('screen.noDataYet')
          }
        </div>
        <div class="toolTipBuild">
          <i class="iconfont ${val.icon}"></i>
          ${val.name}&nbsp;${val.low}℃
        </div>
      </div>`;
  }

  /** @name 初始化地图 */
  async initLoadMap() {
    await this.initChart();
    this.dbclickLoading = true;
  }

  projectObject = {};
  /**
   * @name echart.appendData，动态添加数据
   * @description
   * { Number } seriesIndex 0 待进场项目
   * { Number } seriesIndex 1 已退场项目
   * { Number } seriesIndex 2 已进场项目
   * { Number } seriesIndex 3 已进场有安监终端项目
   */
  initEvent(data) {
    if (!data) return;
    this.projectObject = this.formatProjectData(data);
    projectListMap.set(this.getMapRegisterName(this.type), this.projectObject);
    this.$emit(LOADED_COMPLETE_PROJECT_LIST, this.projectObject);
  }
  getMapRegisterName(type) {
    let typeName = this.countryMapName;
    switch (type) {
      case LEVEL_TYPE_COUNTRY:
        typeName = this.countryMapName;
        break;
      case LEVEL_TYPE_PROVINCE:
        typeName = this.provineMapName;
        break;
      case LEVEL_TYPE_CITY:
        typeName = this.cityMapName;
        break;
      case LEVEL_TYPE_DISTRICT:
        typeName = this.districtMapName;
        break;
      default:
        typeName = this.countryMapName;
    }
    return typeName;
  }
  /** @name 格式化项目数据 */
  formatProjectData(data) {
    const waitList = []; // 待进场项目-WAIT_ENTER
    const outtingList = []; // 已退场项目-QUIT
    const buildingList = []; // 已进场项目-BUILD_LOADING
    const risingList = []; // 已进场项目-BUILD_LOADING
    const electricBoxList = []; // 已进场有安监终端项目-DEVICE_BIND

    if (Array.isArray(data)) {
      data.forEach(v => {
        const { lng, lat } = gcj02tobd09(v.lng, v.lat);
        if (v.process === LEVEL_WAITING) {
          waitList.push({
            ...v,
            value: [lng, lat],
          });
        }
        if (v.process === LEVEL_OUTING) {
          outtingList.push({
            ...v,
            value: [lng, lat],
          });
        }
        if (v.process === LEVEL_BUILDING) {
          buildingList.push({
            ...v,
            value: [lng, lat],
          });
        }
        if (v.process === LEVEL_RISING) {
          risingList.push({
            ...v,
            value: [lng, lat],
          });
        }
        if (v.process === LEVEL_ELECTRIC_BOX) {
          electricBoxList.push({
            ...v,
            value: [lng, lat],
          });
        }
      });
    }

    return {
      waitList,
      outtingList,
      buildingList,
      electricBoxList,
      risingList,
    };
  }
  triggerRegisterEvent(data) {
    const {
      waitList,
      outtingList,
      buildingList,
      electricBoxList,
      risingList,
    } = data;
    waitList.length &&
      this.chart.appendData({
        seriesIndex: 0,
        data: this.waitingButton ? waitList : [],
      });
    outtingList.length &&
      this.chart.appendData({
        seriesIndex: 1,
        data: this.outtingButton ? outtingList : [],
      });
    buildingList.length &&
      this.chart.appendData({
        seriesIndex: 2,
        data: this.buildingButton ? buildingList : [],
      });
    electricBoxList.length &&
      this.chart.appendData({
        seriesIndex: 3,
        data: this.electricBoxButton ? electricBoxList : [],
      });
    risingList.length &&
      this.chart.appendData({
        seriesIndex: 4,
        data: this.risingButton ? risingList : [],
      });
  }

  chart = null;
  loading = false;
  async initChart() {
    this.loading = true;
    if (!this.chart) {
      this.chart = echarts.init(document.getElementById(this.id)); // 这里是为了获得容器所在位置
    }
    await this.registerMap();
    const options = await this.defaultOptions(this.selected);
    this.chart.setOption(options, true, true);

    this.handleEchartsEventListener();
  }
  dbclickLoading = true;
  handleEchartsEventListener() {
    this.chart.off('click'); // 点击事件之前，要释放之前的点击事件
    this.chart.off('dblclick');
    // 单击事件
    this.chart.on('click', params => {
      // 跳转到项目去
      if (params.data && params.data.id) {
        this.$store.dispatch(
          'screen/setBackRouterLinkAction',
          '/iot/generalscreen',
        );
        this.$router.push({
          path: `/iot/screen/buildingFloor/${params.data.id}`,
        });
      }
    });
    // 双击事件切换地图
    this.chart.on('dblclick', async params => {
      if (!this.dbclickLoading) return;
      this.dbclickLoading = false;
      await this.handleMapUrl(params);

      const resultOption = {
        province: this.provineMapName,
        city: this.cityMapName,
        area: this.districtMapName,
      };
      this.$emit('changeMapArea', resultOption);
    });
  }
  async handleMapUrl(params) {
    // error: 双击项目（地图的导致的错误）
    if (!params.name) {
      return false;
    }
    let { adcode, name } = await this.getAdcodeByArea(params.name);
    if (this.type === LEVEL_TYPE_COUNTRY) {
      const provinceName = this.formatProvinceName(params.name);
      this.type = LEVEL_TYPE_PROVINCE;
      this.provineMapName = provinceName;
      this.addMapUrlQueue({
        level: this.type,
        adcode: adcode,
        value: provinceName,
      });
      return false;
    }

    if (this.type === LEVEL_TYPE_PROVINCE) {
      this.type = LEVEL_TYPE_CITY;
      this.cityMapName = name;
      this.addMapUrlQueue({
        level: this.type,
        adcode: adcode,
        value: name,
      });
      return false;
    }
    if (this.type === LEVEL_TYPE_CITY) {
      this.type = LEVEL_TYPE_DISTRICT;
      this.districtMapName = params.name;
      this.addMapUrlQueue({
        level: this.type,
        adcode: adcode,
        value: params.name,
      });
      return false;
    }
  }
  clickDiff = false;
  backTopMapArea() {
    if (this.clickDiff) return false;
    this.deleteMapUrlQueueOfPop();
    this.loading = true;
    this.type = this.lastMapUrlQueueItem.level;
    switch (this.type) {
      case LEVEL_TYPE_COUNTRY:
        this.countryMapName = 'china';
        this.provineMapName = '';
        this.cityMapName = '';
        this.districtMapName = '';
        break;
      case LEVEL_TYPE_PROVINCE:
        this.provineMapName = this.lastMapUrlQueueItem.value;
        this.cityMapName = '';
        this.districtMapName = '';
        break;
      case LEVEL_TYPE_CITY:
        this.cityMapName = this.lastMapUrlQueueItem.value;
        this.districtMapName = '';
        break;
    }
    const resultOption = {
      province: this.provineMapName,
      city: this.cityMapName,
      area: this.districtMapName,
    };
    this.$emit('backTopMapArea', resultOption);
    this.clickDiff = true;
    setTimeout(() => {
      this.clickDiff = false;
    }, 500);
  }
  async registerMap() {
    try {
      let selected = 'china';
      switch (this.type) {
        case LEVEL_TYPE_COUNTRY:
          selected = this.countryMapName;
          break;
        case LEVEL_TYPE_PROVINCE:
          selected = this.provineMapName;
          break;
        case LEVEL_TYPE_CITY:
          selected = this.cityMapName;
          break;
        case LEVEL_TYPE_DISTRICT:
          selected = this.districtMapName;
          break;
      }

      let areaJson = {};
      if (this.lastMapUrlQueueItem.adcode === '100000') {
        areaJson = await getChinaData(`/static/map/100000.json`);
      } else {
        areaJson = await getGeoData(
          this.lastMapUrlQueueItem.adcode || '100000',
        );
      }
      echarts.registerMap(selected, areaJson);
      this.$emit(LOADED_COMPLETE_MAP, selected);
      this.loading = false;
      this.selected = selected;
    } catch (error) {
      return 'china';
    }
  }
}
</script>
<style lang="less" scoped>
// 地图样式
.chartMap {
  background: url('~@/views/iot/screen/assets/images/map-bg.png') center center
    no-repeat;
  background-size: 80%;
  height: 100%;
  width: 100%;
  .bmap {
    z-index: -100;
    position: absolute;
  }
  .chartMapBox {
    ::v-deep .el-loading-mask {
      background-color: rgba(2, 5, 7, 0.7) !important;
    }
  }
  .backMapWrap {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    .backMapLabel {
      display: inline-block;
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;
      background: #fff;
      border: 0.01rem solid #eee;
      color: #ffffff;
      text-align: center;
      box-sizing: border-box;
      transition: 0.1s;
      font-weight: 500;
      padding: 0.12rem 0.2rem;
      font-size: 0.14rem;
      border-radius: 0.04rem;
      background-color: rgba(13, 200, 254, 0.5);
      border-color: rgba(13, 200, 254, 0.5);
    }
  }
  .tipBoxWrap {
    position: absolute;
    bottom: 0;
    left: 26vw;
    .tipItem {
      display: block;
      list-style: none;
      text-align: left;
      margin-bottom: 0.15rem;
      cursor: pointer;
      &:last-of-type {
        margin-bottom: 0;
      }
      .iconBox {
        display: inline-block;
        width: 0.14rem;
        height: 0.14rem;
        border-radius: 100%;
        vertical-align: middle;
        margin-right: 0.05rem;
        &.waitingIcon {
          background-color: rgba(255, 140, 17, 1);
        }
        &.buildingIcon {
          background-color: rgba(243, 233, 37, 1);
        }
        &.outingIcon {
          background-color: rgba(65, 140, 241, 1);
        }
      }
      .labelBox {
        font-size: 0.14rem;
        color: #ffffff;
        display: inline-block;
        vertical-align: middle;
        .otherTip {
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }
}
</style>
<style lang="less">
.projectBuildTool {
  padding: 0.1rem 0.2rem;
  box-sizing: border-box;
  white-space: initial;
  width: 2.56rem;
  .titleTool {
    color: rgba(13, 200, 254, 1);
    font-size: 0.18rem;
    line-height: 0.24rem;
    padding-bottom: 0.16rem;
  }
  .TC,
  .iconfont {
    color: rgba(5, 200, 244, 1);
    font-size: 0.16rem;
  }
}
.toolTipTitle {
  display: block;
  font-size: 0.18rem;
  font-weight: 600;
  color: rgba(13, 200, 254, 1);
  padding-bottom: 0.2rem;
}
.screenAreaBox {
  padding: 0.16rem 0.18rem;
  width: 2rem;
}
.toolTipBuild {
  position: relative;
  margin-bottom: 0.04rem;
  .labelTip {
    width: 1.2rem;
    font-size: 0.14rem;
    display: inline-block;
    color: rgba(255, 255, 255, 0.8);
  }
  .valueTip {
    word-break: break-all;
    // top: 50%;
    // right: 0;
    // transform: translateY(-50%);
  }
}
</style>
