<template>
  <div ref="selectWrap" :class="$style.container">
    <a-select
      :class="$style.selectWrap"
      :getPopupContainer="() => $refs.selectWrap"
      @change="change"
      :value="value"
    >
      <a-icon slot="suffixIcon" type="caret-down" />
      <a-select-option v-for="item in options" :key="item.key">{{
        item.title
      }}</a-select-option>
    </a-select>
  </div>
</template>

<script>
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
@Component
export default class RangeSelect extends Vue {
  @Prop({ type: String, default: undefined }) value;
  @Emit('change')
  @Emit('input')
  change(val) {
    return val;
  }
  options = [
    {
      key: 'NEARLY_SEVEN',
      title: this.$t('matterwebset.screenmanage.last7'),
    },
    {
      key: 'NEARLY_THIRTY',
      title: this.$t('matterwebset.screenmanage.last30'),
    },
    {
      key: 'CURRENT_MONTH',
      title: this.$t('matterwebset.screenmanage.currentmonth'),
    },
  ];
}
</script>

<style lang="less" module>
.selectWrap {
  width: 100%;
  height: 0.4rem;
  :global(.ant-select-selection--single) {
    height: 0.42rem;
    line-height: 0.42rem;
    font-size: 0.14rem;
  }
  :global(.ant-select-arrow svg) {
    transform: rotate(0) !important;
  }
  :global(.ant-select-selection__rendered) {
    height: 0.4rem;
    width: calc(0.8rem - 30px);
    line-height: 0.4rem;
    color: #06d4ff;
    margin-left: 6px;
  }
  :global(.ant-select-selection) {
    box-shadow: unset !important;
    border-radius: unset;
    border-color: transparent !important;
    outline: none;
    color: #02061c;
  }
  :global(.ant-select-selection__placeholder) {
    color: #02061c;
    text-overflow: unset;
    white-space: nowrap;
  }
  :global(.ant-select-arrow) {
    color: #06d4ff;
    font-size: 0.12rem;
    margin-top: -0.06rem;
    right: 0.11rem;
  }
  :global(.ant-select-selection__clear) {
    background-color: transparent;
    color: #02061c;
  }
}
.container {
  position: relative;
  width: 0.8rem;
  height: 0.4rem;
  :global(.ant-select-dropdown) {
    top: 0 !important;
    transform: translateY(0.42rem) !important;
    border-radius: unset;
    font-size: 0.14rem !important;
  }
  :global(.ant-select-dropdown-menu) {
    padding-top: 0;
    padding-bottom: 0;
    border: 1px solid #105071;
    background-color: #020c26;
  }
  :global(.ant-select-dropdown-menu-item) {
    color: #06d4ff;
    line-height: 0.4rem;
    height: 0.4rem;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    text-indent: 11px;
    font-size: 0.14rem !important;
  }
  :global(.ant-select-dropdown-menu-item-selected) {
    background-color: rgba(89, 210, 247) !important;
    font-weight: 400;
    color: #02132a;
  }
  :global(.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled)) {
    background-color: rgba(10, 31, 58);
  }
  :global(.ant-select-dropdown-menu-item-active) {
    background-color: rgba(255, 255, 255, 0.2);
  }
}
</style>
