<template>
  <section class="screenContainer">
    <section class="screenBox">
      <section class="screenHeader">
        <h1 class="headerMain">{{ $t('screen.title') }}</h1>
        <div class="headerLabel">
          <fullScreenBtn
            class="headerOther"
            @fullscreenChange="$emit('header-fullscreen')"
          />
          <span class="headerLeft">
            <slot name="headerLeft" />
          </span>
          <span class="headerOther" @click.stop="handleShowSearchBox">
            <i class="TC tc-icon-sousuo"></i>
          </span>
          <RangeSelect
            class="headerOther"
            slot="headerLeft"
            v-model="range"
            @change="getCountData"
          />
          <span class="headerOther">{{ timeString }}</span>
        </div>
      </section>
      <section class="screenMain">
        <section class="screenContent">
          <div class="screenMainLeft">
            <section class="boxModule">
              <section class="boxModuleBackground">
                <slot name="leftOne">
                  <div class="moduleTitle">楼栋排行榜2</div>
                  <div class="moduleChart"></div>
                </slot>
              </section>
            </section>
            <section class="boxModule">
              <section class="boxModuleBackground">
                <slot name="leftTwo">
                  <div class="moduleTitle">楼栋排行榜2</div>
                  <div class="moduleChart"></div>
                </slot>
              </section>
            </section>
            <section class="boxModule">
              <section class="boxModuleBackground">
                <slot name="leftThree">
                  <div class="moduleTitle">楼栋排行榜2</div>
                  <div class="moduleChart"></div>
                </slot>
              </section>
            </section>
          </div>
          <div class="screenMainCenter">
            <total-count :countObject="countObject" :isToken="false" />
            <mini-map
              ref="miniMapRef"
              :width="'100%'"
              :height="'100%'"
              :handleMoveMapForArea="handleMoveMapForArea"
              :handleMoveMapProjectForData="handleMoveMapProjectForData"
              @changeMapArea="changeMapArea"
              @backTopMapArea="backTopMapArea"
            />
          </div>
          <div class="screenMainRight">
            <section class="boxModule">
              <section class="boxModuleBackground">
                <slot name="rightOne">
                  <div class="moduleTitle">楼栋排行榜2</div>
                  <div class="moduleChart"></div>
                </slot>
              </section>
            </section>
            <section class="boxModule">
              <section class="boxModuleBackground">
                <slot name="rightTwo">
                  <div class="moduleTitle">楼栋排行榜2</div>
                  <div class="moduleChart"></div>
                </slot>
              </section>
            </section>
            <section class="boxModule">
              <section class="boxModuleBackground">
                <slot name="rightThree">
                  <div class="moduleTitle">楼栋排行榜2</div>
                  <div class="moduleChart"></div>
                </slot>
              </section>
            </section>
          </div>
        </section>
      </section>
    </section>
  </section>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import MiniMap from './map.vue';
import TotalCount from '@/views/iot/screen/components/total-count.vue';
import { getDateStr } from '@/utils/common.js';
import GlobalWebSocket from '@/services/socket.js';
import createSearchBox from '@/views/iot/screen/components/screen-header/create-search.js';
import RangeSelect from '@/views/iot/general-screen/components/range-select';
import fullScreenBtn from '@/components/fullscreen-btn';
import {
  SCREEN_TOP_DATA,
  GET_MAP_PROJECT,
  SCREEN,
  MOVE_MAP_PROJECT,
  MOVE_MAP_ADDRESS,
  CONTRACTOR_SCREEN_MANAGE,
  APP_ID_CONTRACTOR_TOP_DATA,
  CONTRACTOR_GET_MAP_PROJECT,
  CONTRACTOR_MOVE_MAP_PROJECT,
} from './socket-event-final';

@Component({
  components: {
    MiniMap,
    TotalCount,
    RangeSelect,
    fullScreenBtn,
  },
})
export default class ScreenWrap extends Vue {
  created() {
    this.socket = new GlobalWebSocket({
      homeId: this.visitorInfo.appId,
    });
    this.socket.webSocketServerListener(this.formatEventSocket);
    this.dateDance();
    let area = '',
      city = '',
      province = '';
    try {
      if (this.visitorInfo.address) {
        const { address } = this.visitorInfo;
        area = address.area;
        city = address.city;
        province = address.province;
      }
    } catch (error) {
      area = '';
      city = '';
      province = '';
    }
    this.getCountData({
      area,
      city,
      province,
    });
  }

  async handleShowSearchBox() {
    await createSearchBox({
      singleTab: true,
      historyBackLink: '/iot/generalscreen',
    });
  }

  timeString = '';
  // 时间跳动
  async dateDance() {
    this.timeString = getDateStr(new Date());
    setTimeout(this.dateDance, 1000);
  }

  /**
   * @name 访客信息
   */
  get visitorInfo() {
    return this.$store.state.generalScreen.visitorInfo;
  }
  handleEmitMapChange(options) {
    this.countDataRequestSize = 0;
    // province表示省份，city表示市，area表示县区
    const { province, city, area } = options;
    this.getCountData({
      area,
      city,
      province,
    });
    this.getProjectByProvinceOrCityData(options);
    this.$emit('change-map', options);
  }
  changeMapArea(options) {
    this.handleEmitMapChange(options);
  }
  backTopMapArea(options) {
    this.handleEmitMapChange(options);
  }

  countObject = {
    safeIndex: 0,
    safeIndexDifference: 0,
    buildLoadingBuildingCount: 0,
    buildLoadingProjectCount: 0,
    totalProjectCount: 0,
    buildLoadingProjectCountDifference: 0,
    buildLoadingBuildingCountDifference: 0,
    totalProjectCountDifference: 0,
  };
  countDataRequestSize = 0; // 请求的次数
  currentAddress = {}; //保存地址信息
  range = 'NEARLY_SEVEN';
  /** @name 获取统计数据  */
  getCountData(address) {
    if (address && address instanceof Object) this.currentAddress = address;
    else address = this.currentAddress;
    const { id: screenManageId, password } = this.visitorInfo;
    let eventName = '';
    if (this.visitorInfo.contractorName) {
      // 租赁商
      eventName = `triascloud-iot-screen:${CONTRACTOR_SCREEN_MANAGE}`;
    } else {
      // 总包和安监局
      eventName = `triascloud-iot-screen:${SCREEN}`;
    }
    this.socket.webSocketEventSend(eventName, {
      address,
      password,
      screenManageId,
      date: this.range,
    });
  }

  /** @name 获取地图散点数据 */
  async getProjectByProvinceOrCityData() {
    this.$refs.miniMapRef && this.$refs.miniMapRef.initLoadMap();
  }

  /** @name 移动到地图项目点数据 */
  async handleMoveMapProjectForData(projectId) {
    let eventName = '';
    if (this.visitorInfo.contractorName) {
      eventName = `triascloud-iot-screen:${CONTRACTOR_MOVE_MAP_PROJECT}`;
    } else {
      eventName = `triascloud-iot-screen:${MOVE_MAP_PROJECT}`;
    }
    this.socket.webSocketEventSend(eventName, {
      projectId,
      screenManageId: this.visitorInfo.id,
    });
  }
  /** @name 鼠标移动到地图的区域，获取区域信息  */
  handleMoveMapForArea(options) {
    this.socket.webSocketEventSend(
      `triascloud-iot-screen:${MOVE_MAP_ADDRESS}`,
      options,
    );
  }
  initFirst = false;
  formatEventSocket(result) {
    let { event, data } = result;
    switch (event) {
      case GET_MAP_PROJECT:
      case CONTRACTOR_GET_MAP_PROJECT:
        if (!this.initFirst) {
          this.$store.commit('project/updateProjects', data);
          this.initFirst = true;
        }
        this.$refs.miniMapRef && this.$refs.miniMapRef.initEvent(data);
        break;
      case SCREEN_TOP_DATA:
      case APP_ID_CONTRACTOR_TOP_DATA:
        this.countObject = {
          ...data,
          ...data.screenManageTopDataDTO,
        };
        break;
      case MOVE_MAP_PROJECT:
      case CONTRACTOR_MOVE_MAP_PROJECT:
        this.$refs.miniMapRef &&
          this.$refs.miniMapRef.handleMoveDataByProject(data);
        break;
      case MOVE_MAP_ADDRESS:
        this.$refs.miniMapRef &&
          this.$refs.miniMapRef.handleMoveDataByArea(data);
        break;
      default:
        this.$emit('socket-send-message', result);
    }
  }

  beforeDestroy() {
    // this.socket.close();
  }
}
</script>

<style lang="less" scoped>
@fff: #fff;
@bgColor: #02051b;
@fontColor: rgba(5, 211, 255, 1);
@fontcolor2: rgba(5, 209, 253, 1);
@lineColor: rgba(13, 200, 254, 1);
@lineColor5: rgba(13, 200, 254, 0.5);
.screenContainer {
  background: @bgColor;
  width: 100%;
  height: 100%;
  padding: 0.12rem;
  box-sizing: border-box;
  display: flex;
  position: relative;
  overflow: hidden;
  /* 滚动条 */
  ::-webkit-scrollbar {
    width: 0rem !important;
    height: 0.08rem;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #075c81;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  .screenBox {
    box-shadow: 0 0 0.06rem 0.02rem rgba(12, 181, 232, 0.4) inset;
    height: 100%;
    width: 100%;
    position: relative;
  }
  .screenHeader {
    height: 0.8rem;
    padding: 0 0.2rem;
    .headerLeft {
      display: inline-block;
      font-size: 0.16rem;
      color: @fontColor;
      position: absolute;
      left: 0.43rem;
    }
    .headerMain {
      background: url('~@/views/iot/screen/assets/images/title-bg.png') center
        top no-repeat;
      height: 0.7rem;
      line-height: 0.6rem;
      display: block;
      background-size: 100% 100%;
      letter-spacing: 0.08rem;
      padding: 0 1.1rem;
      font-size: 0.32rem;
      font-weight: bold;
      color: @fontColor;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
    }
    .headerLabel {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      height: 100%;
      font-size: 0.16rem;
      color: @fontColor;
      .headerOther {
        margin-right: 0.4rem;
        display: inline-block;
        position: relative;
        cursor: pointer;
        &:last-child {
          margin-right: 0.3rem;
        }
        .headerIcon {
          height: 0.14rem;
          width: 0.14rem;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
  .screenMain {
    height: calc(100% - 1.04rem);
    border-top: 0.01rem solid @lineColor5;
    padding: 0.2rem;
    .screenContent {
      height: 100%;
      width: 100%;
      position: relative;
    }
    .screenMainLeft {
      width: 21.8vw;
      min-width: 2.5rem;
      overflow-y: hidden;
      height: 100%;
      position: absolute;
      z-index: 3;
      display: flex;
      flex-direction: column;
    }
    .screenMainCenter {
      width: 100%;
      position: relative;
      z-index: 1;
      height: 100%;
    }
    .screenMainRight {
      width: 21.8vw;
      min-width: 2.5rem;
      overflow-y: hidden;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 3;
      display: flex;
      flex-direction: column;
    }
  }
  // 模块样式
  .boxModule {
    margin-bottom: 3.5%;
    width: 100%;
    box-shadow: 0 0 0.18rem 0.02rem rgba(12, 181, 232, 0.4) inset;
    background: linear-gradient(#07d3fd, #07d3fd) left top,
      linear-gradient(#07d3fd, #07d3fd) left top,
      linear-gradient(#07d3fd, #07d3fd) right top,
      linear-gradient(#07d3fd, #07d3fd) right top,
      linear-gradient(#07d3fd, #07d3fd) left bottom,
      linear-gradient(#07d3fd, #07d3fd) left bottom,
      linear-gradient(#07d3fd, #07d3fd) right bottom,
      linear-gradient(#07d3fd, #07d3fd) right bottom;
    background-repeat: no-repeat;
    background-size: 0.02rem 0.2rem, 0.2rem 0.02rem;
    padding: 0.01rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-size: 0.16rem;
    position: relative;
    flex: 1;
    min-height: 2rem;
    .boxModuleBackground {
      background-color: @bgColor;
      height: 100%;
    }
    .moduleTitle {
      height: 0.4rem;
      width: 100%;
      font-weight: normal;
      color: #05d2fe;
      line-height: 0.4rem;
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(rgba(5, 211, 255, 0.2)),
        color-stop(98%, transparent)
      );
      background: linear-gradient(
        90deg,
        rgba(5, 211, 255, 0.2) 0%,
        transparent 98%
      );
      padding: 0 0.2rem;
      box-sizing: border-box;
    }
    .moduleChart {
      height: calc(100% - 0.4rem);
      overflow-x: hidden;
      overflow-y: auto;
      color: #fff;
      width: 100%;
      box-sizing: border-box;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
