//-------- 总包和安监局（区域） ------------
/**@name 顶部数据 */
export const SCREEN_TOP_DATA = 'APP_ID_TOP_DATA';
/**@name 地图数据 */
export const GET_MAP_PROJECT = 'GET_MAP_PROJECT';
/**@name 唯一事件 */
export const SCREEN = 'SCREEN_MANAGE';
/** @name  移动到地图项目点数据 */
export const MOVE_MAP_PROJECT = 'MOVE_MAP_PROJECT';
/** @name 鼠标移动到地图的区域*/
export const MOVE_MAP_ADDRESS = 'MOVE_MAP_ADDRESS';

//-------- 租赁商 ------------
/** @name 承包商唯一事件 */
export const CONTRACTOR_SCREEN_MANAGE = 'CONTRACTOR_SCREEN_MANAGE';
/** @name 承包商大屏顶部参数 */
export const APP_ID_CONTRACTOR_TOP_DATA = 'APP_ID_CONTRACTOR_TOP_DATA';
/** @name 承包商大屏获取地获图数据  */
export const CONTRACTOR_GET_MAP_PROJECT = 'CONTRACTOR_GET_MAP_PROJECT';
/** @name 承包商大屏移动到地图项目点数据 */
export const CONTRACTOR_MOVE_MAP_PROJECT = 'CONTRACTOR_MOVE_MAP_PROJECT';
