<template>
  <div :class="$style.container">
    <no-data-chart v-if="isNoData" />
    <div v-else :id="id" :class="$style.chartBox" :style="{ height }" />
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as echarts from 'echarts';
import resize from '@/utils/resize.js';
import { nowSize } from '@/utils/common.js';
import { screenModule } from '@/enum/store';

const barOption = data => {
  const category = data.map(e => e.projectName);
  const values = data.map(e => e.safeIndex);

  const grid = {
    x: 50,
    y: 30,
    x2: 30,
    y2: 60,
  };
  const series = [
    {
      type: 'bar',
      hoverAnimation: false,
      data: values,
      barWidth: '20px',
      itemStyle: {
        normal: {
          // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
          color: new echarts.graphic.LinearGradient(
            0,
            1,
            0,
            0,
            [
              {
                offset: 0,
                color: '#ea9053', // 0% 处的颜色
              },
              {
                offset: 1,
                color: '#fcb644', // 100% 处的颜色
              },
            ],
            false,
          ),
        },
      },
    },
  ];
  const tooltip = {
    trigger: 'item',
    confine: true,
    axisPointer: { type: 'none' },
    backgroundColor: 'rgba(3,29,63,0.6)',
    borderColor: '#0DC8FE',
    borderWidth: '1',
    padding: 10,
    textStyle: {
      color: '#fff',
      fontSize: '14px',
    },
    position: function(pos, params, dom, rect, size) {
      // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
      var obj = { top: 60 };
      obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
      return obj;
    },
    formatter(params) {
      const { name, value } = params;
      return `<div><p style="width: 170px; white-space: break-spaces;line-height: 1;">项目名称: <span style="color: #fff;">${name}</span></p><br/><p >项目安全指数: <span style="color: #fff;"> ${value}%</span></p></div>`;
    },
    extraCssText: 'border-radius: 0;z-index:10000;',
  };
  return {
    tooltip,
    grid,
    xAxis: {
      type: 'category',
      triggerEvent: true,
      axisTick: {
        show: false,
        alignWithLabel: true,
      },
      data: category,
      axisLine: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        color: '#a9adc0',
        fontSize: nowSize(12),
        rotate: 30,
        formatter(val) {
          return val.length > 6 ? `${val.substr(0, 6)}…` : val;
        },
      },
    },
    yAxis: {
      type: 'value',
      min: 0,
      max: 100,
      interval: 25,
      splitLine: {
        show: true,
        lineStyle: {
          color: '#121329',
        },
      },
      axisLabel: {
        textStyle: {
          color: '#9ca0b0',
        },
        formatter(val) {
          return val + '%';
        },
        fontSize: nowSize(12),
      },
      // 隐藏刻度线
      axisTick: { show: false },
      axisLine: {
        show: false,
      },
    },

    series,
  };
};
/**
 * @class bar 图系 单bar 或 叠bar
 */
@Component({
  mixins: [resize],
  mounted() {
    if (this.data && this.data.length) this.initChart();
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
})
export default class SafeRank extends Vue {
  @screenModule.Action('setBackRouterLinkAction') setBackRouterLink;

  @Prop({ type: String, default: 'safeRank' }) id;
  @Prop({ type: String, default: '100%' }) height;
  @Prop({ type: Array, default: null }) data;
  get isNoData() {
    if (!this.data || !this.data.length) {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
      return true;
    } else {
      this.$nextTick(this.initChart);
      return false;
    }
  }
  initChart() {
    if (!this.roseChart)
      this.chart = echarts.init(document.getElementById(this.id));
    const options = barOption(this.data);
    this.chart.setOption(options);
    this.chart.off('click');
    this.chart.on('click', data => {
      const { componentType, value, name } = data;
      if (componentType === 'series') {
        this.setBackRouterLink('/iot/generalScreen');
        this.$router.push({
          path: `/iot/screen/buildingFloor/${
            this.data.find(e => e.projectName === name).projectId
          }`,
        });
      } else {
        this.setBackRouterLink('/iot/generalScreen');
        this.$router.push({
          path: `/iot/screen/buildingFloor/${
            this.data.find(e => e.projectName === value).projectId
          }`,
        });
      }
    });
  }
}
</script>

<style lang="less" module>
.container {
  height: 100%;
  position: relative;
}
.chartBox {
  width: 100%;
}
</style>
