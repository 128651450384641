<template>
  <span class="TC tc-icon-jieshishuoming" :class="$style.icon">
    <span :class="$style.title">{{ title }}</span>
  </span>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component
export default class Tip extends Vue {
  @Prop({ type: String, default: '' }) title;
}
</script>

<style lang="less" module>
.icon {
  color: #1b445b;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.1rem;
  .title {
    display: none;
    width: 2.1rem;
    z-index: 1000;
    font-size: 0.14rem;
    line-height: 0.2rem;
    padding: 0.1rem;
    border: 1px solid #084167;
    background-color: #061536;
    color: #fff;
    position: absolute;
    top: 0.4rem;
    left: 40%;
  }
  &:hover {
    .title {
      display: block;
    }
  }
}
</style>
