<template>
  <screen @change-map="handleChangeMap" @socket-send-message="handleSocketMes">
    <template slot="headerLeft" v-if="visitorInfo">
      <!-- 总包 -->
      <span v-if="visitorInfo && visitorInfo.epcId"
        ><span class="TC tc-icon-qiye1" :class="$style.headIcon"></span
        >{{ visitorInfo.epcName }}</span
      >
      <!-- 租赁商 -->
      <span v-else-if="visitorInfo && visitorInfo.contractorName"
        ><span class="TC tc-icon-qiye1" :class="$style.headIcon"></span
        >{{ visitorInfo.contractorName }}</span
      >
      <!-- 安监局 -->
      <span v-else
        ><span class="TC tc-icon-dizhi3 " :class="$style.headIcon"></span
        >{{ $t('screen.currentArea') }}：{{ address }}</span
      >
    </template>
    <template slot="leftOne">
      <div class="moduleTitle" :class="$style.title">
        <span>{{ $t('screen.eventTrends') }}</span>
      </div>
      <div :class="$style.chartBox">
        <warnTrend id="warnTrend" :data="warnTrend" />
      </div>
    </template>
    <template slot="leftTwo">
      <div class="moduleTitle" :class="$style.title">
        <span>{{
          isEpc ? $t('screen.WarningStatistics') : $t('screen.eventTypes')
        }}</span>
        <div v-if="isEpc">
          <span
            :class="[
              $style.titleLeft,
              !warningViewContent ? $style.active : '',
            ]"
            @click="changeWaringView(0)"
            >{{ $t('screen.warningTypes') }}</span
          >
          <span
            :class="[$style.titleLeft, warningViewContent ? $style.active : '']"
            @click="changeWaringView(1)"
            >{{ $t('screen.areaDistribution') }}</span
          >
        </div>
      </div>
      <div :class="$style.chartBox">
        <el-carousel
          @change="val => (warningViewContent = val)"
          :autoplay="false"
          loop
          height="100%"
          arrow="never"
          indicator-position="none"
          ref="warningViewCarousel"
        >
          <el-carousel-item
            :class="$style.carouselItem"
            :key="0"
            :interval="3000"
          >
            <div :class="$style.inChartBox">
              <areaRoseChart
                chartType="pie"
                id="waringPie"
                :data="warningStyle"
                :options="{ cateKey: 'key', valueKey: 'num' }"
              />
            </div>
          </el-carousel-item>
          <el-carousel-item v-if="isEpc" :class="$style.carouselItem" :key="1">
            <div :class="$style.inChartBox">
              <areaRoseChart
                id="areaRingRose"
                :options="{
                  roseType: false,
                  title: $t('screen.warningTotalcount'),
                  cateKey: 'key',
                  valueKey: 'num',
                }"
                :data="warningArea"
              />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </template>
    <template v-if="!isEpc" slot="leftThree">
      <div class="moduleTitle">
        {{ $t('screen.eventAreas') }}
      </div>
      <div :class="$style.chartBox">
        <areaRoseChart
          id="areaRing"
          :options="{
            roseType: false,
            title: $t('screen.warningTotalcount'),
            cateKey: 'key',
            valueKey: 'num',
          }"
          :data="warningArea"
        />
      </div>
    </template>
    <template :slot="isEpc ? 'leftThree' : 'rightOne'">
      <div class="moduleTitle" :class="$style.title">
        <span>{{ $t('screen.eventsRank') }}<Tip :title="tips[0]"/></span>
        <div>
          <span
            :class="[$style.titleLeft, !warnRankHander ? $style.active : '']"
            @click="setWarnRank(0)"
            >{{ $t('screen.top5') }}</span
          >
          <span
            v-if="averageWarnRankTotal.length > 5"
            :class="[$style.titleLeft, warnRankHander ? $style.active : '']"
            @click="setWarnRank(1)"
            >{{ $t('screen.last5') }}</span
          >
        </div>
      </div>
      <div :class="$style.chartBox">
        <el-carousel
          @change="val => (warnRankHander = val)"
          :autoplay="false"
          loop
          height="100%"
          arrow="never"
          indicator-position="none"
          ref="warnRankCarousel"
        >
          <el-carousel-item
            :class="$style.carouselItem"
            :key="0"
            :interval="3000"
          >
            <div :class="$style.inChartBox">
              <AverageRank id="warnRank" :data="averageWarnRank" />
            </div>
          </el-carousel-item>
          <el-carousel-item :class="$style.carouselItem" :key="1">
            <div :class="$style.inChartBox">
              <AverageRank
                id="warnRankReverse"
                :data="averageWarnRankReverse"
              />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </template>
    <template :slot="isEpc ? 'rightOne' : 'rightTwo'">
      <div class="moduleTitle">
        {{ $t('screen.safeRateTrends') }}
      </div>
      <div :class="$style.chartBox">
        <safeIndex
          id="safeIndexSeven"
          :data="safeIndexData"
          :options="{ cateKey: 'time', valueKey: 'safeIndex' }"
          @showSafeModal="showSafeModal"
        />
      </div>
    </template>
    <template :slot="isEpc ? 'rightTwo' : 'rightThree'">
      <div class="moduleTitle">
        {{ $t('screen.last5SafeRate') }}<Tip :title="tips[1]" />
      </div>
      <div :class="$style.chartBox">
        <safeRank :data="safeRank" />
      </div>
    </template>
    <template v-if="isEpc" slot="rightThree">
      <div class="moduleTitle" :class="$style.title">
        <span>{{ $t('screen.buildingLiftDays') }}<Tip :title="tips[2]"/></span>
        <div>
          <span
            :class="[$style.titleLeft, !riseRankHander ? $style.active : '']"
            @click="setRiseRank(0)"
            >{{ $t('screen.top5') }}</span
          >
          <span
            v-if="averageRiseRankTotal.length > 5"
            :class="[$style.titleLeft, riseRankHander ? $style.active : '']"
            @click="setRiseRank(1)"
            >{{ $t('screen.last5') }}</span
          >
        </div>
      </div>
      <div :class="$style.chartBox">
        <el-carousel
          @change="val => (riseRankHander = val)"
          :autoplay="false"
          loop
          height="100%"
          arrow="never"
          indicator-position="none"
          ref="riseRankCarousel"
        >
          <el-carousel-item
            :class="$style.carouselItem"
            :key="0"
            :interval="3000"
          >
            <div :class="$style.inChartBox">
              <AverageRank
                id="riseRank"
                :option="{
                  valueKey: 'value',
                  centerKey: 'name',
                  color: ['#2796ff', '#03d6ff'],
                }"
                :data="averageRiseRank"
              />
            </div>
          </el-carousel-item>
          <el-carousel-item :class="$style.carouselItem" :key="1">
            <div :class="$style.inChartBox">
              <AverageRank
                id="riseRankReverse"
                :option="{
                  valueKey: 'value',
                  centerKey: 'name',
                  color: ['#2796ff', '#03d6ff'],
                }"
                :data="averageRiseRankReverse"
              />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </template>
  </screen>
</template>
<script>
import '@/assets/css/reset.css';
import { Component, Vue } from 'vue-property-decorator';
import areaRoseChart from './components/charts/area-rose-chart';
import safeIndex from './components/charts/safe-index';
import SafeRank from './components/charts/safe-rank';
import warnTrend from './components/charts/warn-trend';
import AverageRank from './components/charts/average-rank';
import Screen from '@/components/screen';
import Tip from './components/tip';
import dayjs from 'dayjs';
import { flexDpr } from '@/assets/js/flex-dpr.js';
import SafeModal from '@/views/iot/screen/components/common/safe-modal';
import createModal from '@/views/iot/screen/components/message/modal.js';
import {
  APP_ID_DAY_RANGE_SAFETY_RATE_COUNT,
  APP_ID_DAY_RANGE_ALARM_MESSAGE_ADDRESS_LIST,
  APP_ID_PROJECT_AVERAGE_RANK_ALARM,
  APP_ID_BUILDING_AVERAGE_RISE_COUNT,
  APP_ID_PROJECT_SAFETY_RATE_COUNT,
  APP_ID_CONTRACTOR_DAY_RANGE_SAFETY_RATE_COUNT,
  APP_ID_DAY_RANGE_ALARM_MESSAGE_CONTRACTORLIST,
  APP_ID_CONTRACTOR_AVERAGE_RANK_BY_ALARM,
  APP_ID_CONTRACTOR_BUILDING_AVERAGE_RISE_COUNT,
  APP_ID_CONTRACTOR_SAFETY_RATE_COUNT,
} from './socket-event';

flexDpr();

@Component({
  components: {
    areaRoseChart,
    safeIndex,
    Screen,
    AverageRank,
    warnTrend,
    SafeRank,
    Tip,
  },
})
export default class GeneralScreen extends Vue {
  safeIndexData = null;
  safeRank = null;
  warningStyle = null;
  warningArea = null;
  warnTrend = null;
  averageWarnRank = [];
  averageWarnRankReverse = [];
  averageRiseRank = [];
  averageRiseRankReverse = [];
  averageWarnRankTotal = [];
  averageRiseRankTotal = [];
  site = null;
  tips = [
    this.$t('screen.areaShareTips1'),
    this.$t('screen.areaShareTips2'),
    this.$t('screen.areaShareTips3'),
  ];
  /**
   * @description 是显示总包的大屏结果
   * 总包或者租赁商的结果
   */
  get isEpc() {
    return !!(
      this.visitorInfo &&
      (this.visitorInfo.epcId || this.visitorInfo.contractorName)
    );
  }
  /**@name 前后切换 */
  warnRankHander = 0;
  riseRankHander = 0;
  /**
   * @name 7天预警统计查看内容
   */
  warningViewContent = 0;
  /**
   * @name 改变预警统计内容指针
   */
  changeWaringView(val) {
    this.warningViewContent = val;
    this.$refs.warningViewCarousel.setActiveItem(val);
  }
  setWarnRank(val) {
    this.warnRankHander = val;
    this.$refs.warnRankCarousel.setActiveItem(val);
  }
  setRiseRank(val) {
    this.riseRankHander = val;
    this.$refs.riseRankCarousel.setActiveItem(val);
  }
  /**
   * @name 是否为楼栋展示
   */
  get isShowBuild() {
    return (
      (this.site && this.site.county) ||
      (this.visitorInfo.address && this.visitorInfo.address.area)
    );
  }

  /**
   * @name 安监局地址
   */
  get address() {
    if (this.visitorInfo && this.visitorInfo.address) {
      const { province, city, area } = this.visitorInfo.address;
      return province ? `${province}${city}${area}` : this.$t('screen.china');
    } else {
      return '';
    }
  }
  /**
   * @name 处理socket传递消息
   */
  eventEnum = {
    分控箱超载: this.$t('electricBox.error.BranchControlBoxOverload'),
    超载预警: this.$t('electricBox.warn.overloadEvent'),
    分控箱失载: this.$t('electricBox.error.SubBoxLossOfLoad'),
    失载预警: this.$t('electricBox.warn.underloadEvent'),
    主控箱急停: this.$t('electricBox.error.EmergencyStopOfMainControlBox'),
    分控箱倾斜: this.$t('electricBox.error.SubBoxTilt'),
    倾斜预警: this.$t('electricBox.warn.tiltEvent'),
    分控箱掉线: this.$t('electricBox.error.SubBoxIsOffline'),
    分控箱急停: this.$t('electricBox.error.EmergencyStopOfSubBox'),
    总电流预警: this.$t('electricBox.warn.totalElectricity'),
    总荷载预警: this.$t('electricBox.warn.totalLoad'),
    天气预警: this.$t('electricBox.warn.weatherEvents'),
    位置预警: this.$t('electricBox.warn.LocationWarning'),
    封顶预警: this.$t('electricBox.warn.CappingWarning'),
    分控箱连接: this.$t('electricBox.warn.SubBoxWarning'),
  };
  handleSocketMes(result) {
    const { data, event } = result;
    switch (event) {
      case APP_ID_DAY_RANGE_SAFETY_RATE_COUNT:
      case APP_ID_CONTRACTOR_DAY_RANGE_SAFETY_RATE_COUNT:
        // 安全指数趋势
        {
          this.safeIndexData = data
            .sort((a, b) => a.time - b.time)
            .map(e => ({
              ...e,
              time: dayjs(e.time).format('MM-DD'),
              safeIndex: e.safeIndex,
            }));
        }
        break;
      case APP_ID_DAY_RANGE_ALARM_MESSAGE_ADDRESS_LIST:
      case APP_ID_DAY_RANGE_ALARM_MESSAGE_CONTRACTORLIST:
        // 预警统计--预警类型 warningStyle
        // 预警统计--区域分布 warningArea
        // 预警趋势 warnTrend
        {
          const [warningStyle, warningArea, warnTrend] = this.handleWarnData(
            data,
          );
          this.warningStyle = warningStyle.map(item => {
            const key = this.eventEnum[item.key] || item.key;
            item.key = key;
            return item;
          });
          warningArea && (this.warningArea = warningArea);
          this.warnTrend = warnTrend;
        }
        break;
      case APP_ID_PROJECT_AVERAGE_RANK_ALARM:
      case APP_ID_CONTRACTOR_AVERAGE_RANK_BY_ALARM:
        // 项目平均预警数排行--前五名 averageWarnRank
        // 项目平均预警数排行--后五名 averageWarnRankReverse
        this.averageWarnRankTotal = data;
        this.averageWarnRank = data.slice(0, 5).reverse();
        this.averageWarnRankReverse = data.slice(-5).reverse();
        break;
      case APP_ID_BUILDING_AVERAGE_RISE_COUNT:
      case APP_ID_CONTRACTOR_BUILDING_AVERAGE_RISE_COUNT:
        // 楼栋平均提升天数--前五名 averageRiseRank
        // 楼栋平均提升天数--后五名 averageRiseRankReverse
        this.averageRiseRankTotal = data;
        this.averageRiseRank = data.slice(0, 5).reverse();
        this.averageRiseRankReverse = data.slice(-5).reverse();
        break;
      case APP_ID_PROJECT_SAFETY_RATE_COUNT:
      case APP_ID_CONTRACTOR_SAFETY_RATE_COUNT:
        // 项目安全指数排行（最后五名）
        this.safeRank = data;
        break;
    }
  }
  /**
   * @name 大屏类型 总包/安监局
   */
  get screenType() {
    return this.visitorInfo && this.visitorInfo.type === 'EPC';
  }
  /**
   * @name 访客信息
   */
  get visitorInfo() {
    return this.$store.state.generalScreen.visitorInfo;
  }
  /**
   * @name 处理预警统计数据
   */
  handleWarnData(data) {
    data = data.sort((a, b) => a.time - b.time);
    const arr = [];
    let areaArr = [];
    let warnTrend = [];
    let filter = 'province';
    if (this.site) {
      const { province, city, county } = this.site;
      filter = county
        ? 'build'
        : city
        ? 'area'
        : province
        ? 'city'
        : 'province';
    } else {
      const { province, city, area } = this.visitorInfo.address || {};
      filter = area ? 'build' : city ? 'area' : province ? 'city' : 'province';
    }
    filter === 'build' && (areaArr = null);
    data.forEach(e => {
      const existed = arr.find(el => el.key === e.type);
      const timeFind = warnTrend.find(
        el => el.key === dayjs(e.time).format('MM-DD'),
      );
      if (existed) {
        existed.num++;
      } else {
        arr.push({ key: e.type, num: 1 });
      }
      if (timeFind) {
        timeFind.num++;
      } else {
        warnTrend.push({ key: dayjs(e.time).format('MM-DD'), num: 1 });
      }

      if (filter === 'build') return;
      const addressExist = areaArr.find(el => e.address[filter] === el.key);
      if (addressExist) {
        addressExist.num++;
      } else {
        areaArr.push({ key: e.address[filter], num: 1 });
      }
    });
    return [arr, areaArr, warnTrend];
  }
  /**
   * @name 处理已进场项目
   */
  // handleBuildData(data) {
  //   let filter = 'province';
  //   const areaArr = [];
  //   if (this.site) {
  //     const { province, city, county } = this.site;
  //     filter = county
  //       ? 'build'
  //       : city
  //       ? 'county'
  //       : province
  //       ? 'city'
  //       : 'province';
  //   } else {
  //     const { province, city, area } = this.visitorInfo.address || {};
  //     filter = area
  //       ? 'build'
  //       : city
  //       ? 'county'
  //       : province
  //       ? 'city'
  //       : 'province';
  //   }
  //   filter === 'build'
  //     ? Object.keys(data).forEach(e => {
  //         areaArr.push({ name: e, number: data[e] });
  //       })
  //     : data.forEach(e => {
  //         const addressExist = areaArr.find(el => e[filter] === el.name);
  //         if (addressExist) {
  //           addressExist.number++;
  //         } else {
  //           areaArr.push({ name: e[filter], number: 1 });
  //         }
  //       });
  //   return areaArr.filter(e => e.number);
  // }
  /**
   * @name 处理区级区域预警
   */
  handleAreaAlarm(data) {
    const areaArr = [];
    Object.keys(data).forEach(e => {
      areaArr.push({ key: e, num: data[e] });
    });
    return areaArr.filter(e => e.num);
  }
  /**
   * @name 处理地图切换
   */
  handleChangeMap(val) {
    this.site = val;
  }
  showSafeModal(index) {
    const { timeStamp, avgSafetyRateDTOS } = this.safeIndexData[index];
    const columns = [
      {
        title: this.$t('matterwebset.electricbox.projectname'),
        key: 'projectName',
        width: 300,
      },
      {
        title: this.$t('screen.safetyRate'),
        key: 'avgSafetyRate',
        width: 60,
      },
    ];
    const modal = createModal(
      () => (
        <SafeModal
          columns={columns}
          data={avgSafetyRateDTOS.slice(0, 10)}
          onRowClick={item => {
            this.$router.push({
              path: '/iot/screen/projectBuilding/' + item.projectId,
            });
            modal.handleClose();
          }}
          showArrow
        />
      ),
      {
        title: `${dayjs(timeStamp).format('YYYY-MM-DD') +
          this.$t('screen.last10SafeRae')}`,
        width: this.$size(508).toString(),
        showButton: false,
      },
    );
  }
}
</script>

<style lang="less" module>
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .titleLeft {
    margin-left: 0.1rem;
    cursor: pointer;
    &.active {
      color: #d1d3d5;
    }
  }
}
.chartBox {
  height: calc(100% - 0.4rem);
  > div {
    height: 100%;
  }
}
.inChartBox {
  width: 100%;
  height: 100%;
}
.listBox {
  height: calc(100% - 0.4rem);
  overflow-x: hidden;
  overflow-y: auto;
}
.headIcon {
  margin-right: 0.1rem;
}
</style>
