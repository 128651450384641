<template>
  <no-data-chart v-if="isNoData" />
  <div v-else :id="id" :class="$style.chartBox" :style="{ height }"></div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as echarts from 'echarts';
import resize from '@/utils/resize';
import { screenModule } from '@/enum/store';

const barOptions = (data, option = {}, $t) => {
  const {
    catekey = 'projectName',
    valueKey = 'averageAlarmCount',
    centerKey = 'enterBuildCount',
    color = ['#8947fd', '#a070f0'],
  } = option;
  const categorys = [];
  const values = [];
  let max = 0;
  data.forEach(e => {
    categorys.push(e[catekey]);
    values.push(e[valueKey]);
    max = Math.max(e[valueKey], max);
  });
  max = Math.max(100, max);
  return {
    tooltip: {
      confine: true,
      axisPointer: {
        trigger: 'axis',
        lineStyle: {
          color: '#e6e6e6',
        },
      },
      backgroundColor: 'rgba(3,29,63,0.6)',
      borderColor: '#0DC8FE',
      borderWidth: '1',
      padding: 10,
      textStyle: {
        color: '#9fa5b2',
        fontSize: '14px',
      },
      formatter: function(params) {
        const { name, value } = params;
        const isWarn = centerKey === 'enterBuildCount';
        return `<div><p style="white-space: break-spaces;line-height: 1;">${$t(
          'electricBox.table.projectName',
        )}: <span style="color: #fff;">${name}</span></p><br/><p style="white-space: break-spaces;line-height: 1;">${
          isWarn
            ? $t('screen.count.startedBuilding')
            : $t('screen.buildingName')
        }: <span style="color: #fff;"> ${
          data.find(e => e[catekey] === name)[centerKey]
        }</span></p><p style="white-space: break-spaces;line-height: 1;">${
          isWarn ? $t('screen.avgWarnings') : $t('screen.averageLiftDays')
        }: <span style="color: #fff;"> ${value} ${
          isWarn ? $t('screen.times') : $t('screen.days')
        }</span></p></div>`;
      },
      extraCssText: 'border-radius: 0;z-index:10000;',
    },
    grid: {
      x: 10,
      y: 10,
      x2: 45,
      y2: 20,
      containLabel: true,
    },
    yAxis: {
      type: 'category',
      boundaryGap: true,
      data: categorys,
      axisTick: { show: false },
      axisLine: {
        show: false,
      },
      triggerEvent: true,
      axisLabel: {
        fontSize: 14,
        color: '#99999e',
        formatter(val) {
          return val.length > 6 ? `{a|${val.substr(0, 6)}…}` : `{b|${val}}`;
        },
        rich: {
          a: {
            align: 'left',
          },
          b: {
            align: 'right',
          },
        },
        width: 80,
      },
    },
    xAxis: {
      min: 0,
      max, // 设置y轴坐标最大值
      interval: max,
      splitLine: {
        show: false,
      },
      type: 'value',
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
    },
    series: [
      {
        // For shadow
        type: 'bar',
        barWidth: 22,
        itemStyle: {
          color: '#020f27',
        },
        barGap: '-100%',
        barCategoryGap: '40%',

        data: values.map(() => max),
        animation: false,
        silent: true,
      },
      {
        type: 'bar',
        barWidth: 22,
        itemStyle: {
          normal: {
            // 每根柱子颜色设置
            color: new echarts.graphic.LinearGradient(
              0,
              1,
              0,
              0,
              [
                {
                  offset: 0,
                  color: color[0], // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: color[1], // 100% 处的颜色
                },
              ],
              false,
            ),
            label: {
              textStyle: {
                color: color[0],
                fontSize: 12,
              },
            },
          },
        },
        label: {
          normal: {
            show: true,
            position: 'right',
            formatter: ({ dataIndex }) => {
              return `${values[dataIndex]} ` + $t('screen.times');
            },
            color: '#999',
            distance: 8,
          },
        },
        data: values,
      },
    ],
  };
};
@Component({
  mixins: [resize],
})
export default class AverageRank extends Vue {
  @screenModule.Action('setBackRouterLinkAction') setBackRouterLink;

  @Prop({ type: String, default: 'bar-h' }) id;
  @Prop({ type: Object, default: () => {} }) option;
  @Prop({ type: String, default: '100%' }) height;
  @Prop({ type: Array, default: () => [] }) data;
  mounted() {
    if (this.data && this.data.length) this.initChart();
  }
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
  get isNoData() {
    if (!this.data || !this.data.length) {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
      return true;
    } else {
      this.$nextTick(this.initChart);
      return false;
    }
  }
  initChart() {
    if (!this.chart)
      this.chart = echarts.init(document.getElementById(this.id));
    const options = barOptions(this.data, this.option, this.$t);
    this.chart.setOption(options);
    this.chart.off('click');
    this.chart.on('click', data => {
      const { componentType, value, name } = data;
      if (componentType === 'series') {
        this.setBackRouterLink('/iot/generalScreen');
        this.$router.push({
          path: `/iot/screen/buildingFloor/${
            this.data.find(e => e.projectName === name).projectId
          }`,
        });
      } else {
        this.setBackRouterLink('/iot/generalScreen');
        this.$router.push({
          path: `/iot/screen/buildingFloor/${
            this.data.find(e => e.projectName === value).projectId
          }`,
        });
      }
    });
  }
}
</script>
<style lang="less" module>
.container {
  height: 100%;
  position: relative;
}
.noDataBox {
  position: relative;
  width: 100%;
  height: 100%;
  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
    color: #07d3fd;
  }
}
.chartBox {
  width: 100%;
  // & > div {
  //   width: 100%;
  //   height: 100%;
  // }
}
</style>
